import React, { useState, useEffect } from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";

import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BsTwitter, BsInstagram } from "react-icons/bs";
import { RiLinkedinFill } from "react-icons/ri";
import { FaFacebookF } from "react-icons/fa";

const Contact = () => {
  const { t } = useTranslation();

  const [inputs, setInputs] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [serverState, setServerState] = useState({
    status: "",
  });

  const [errorMessage, setErrorMessage] = useState({});

  const validationRules = {
    name: (val) => !!val,
    phone: (val) => !!val,
    email: (val) => val && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val),
    message: (val) => !!val,
  };

  const validate = () => {
    let errors = {};
    let hasError = false;
    Object.keys(inputs).forEach((item) => {
      errors[item] = !validationRules[item](inputs[item]);
      hasError |= errors[item];
    });
    setErrorMessage((prev) => ({ ...prev, ...errors }));
    return !hasError;
  };

  const renderFieldError = (fieldError) => {
    if (errorMessage[fieldError]) {
      return <p className="errorMsg">{t(`error_${fieldError}`)}</p>;
    }
  };

  useEffect(() => {
    if (Object.keys(errorMessage).length > 0) {
      validate();
    }
  }, []);

  const handelChange = (e) => {
    e.persist();
    setInputs((inp) => ({
      ...inp,
      [e.target.name]: e.target.value,
    }));
  };

  const handelSubmit = (e) => {
    e.preventDefault();

    let formData = {
      subject: "client mail",
      text: `\nname: ${inputs.name}\nphone: ${inputs.phone}\nemail: ${inputs.email}\nmessage: ${inputs.message}`,
    };

    let data = JSON.stringify(formData);
console.log(data);
    if (!validate()) {
      setServerState({
        status: "fill",
      });
    } else {
      axios
        .post(`https://admin.cannula-ms.com/sendmail`, data, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          console.log(res);
          if (res.ok) {
            setErrorMessage({});
            setInputs({
              name: "",
              phone: "",
              email: "",
              message: "",
            });
            setServerState({
              status: "success",
            });
            setTimeout(() => {
              setServerState({
                status: "",
              });
            }, 2000);
          }
        })
        .catch((err) => {
          setServerState({
            status: "error",
          });
          console.log(err);
        });
    }
  };


  return (
    <section className="section-contact" id="contact">
      <div className="contact-container mx-auto">
        <div className="contact-info">
          <div className="contact-info-contact">
            <h2>{t("Contact Us")}</h2>
            <p>
              {t("Contact")}
              <br />
              {t("Contact2")}
            </p>
          </div>
          <div className="contact-info-address">
            <ul>
              <li>
                <span className="icon">
                  <FiPhoneCall size="20" />
                </span>
                <div className="contact">
                  <span>{t("Call Us")}</span>
                  <h4>+20 109 993 1943</h4>
                </div>
              </li>
              <li>
                <span className="icon">
                  <AiOutlineMail size="20" />
                </span>
                <div className="contact">
                  <span>{t("email")}</span>
                  <h4>info@cannula-ms.com</h4>
                </div>
              </li>
              <li>

  
              </li>
            </ul>
          </div>
          <div className="contact-info-find">
            <div>
              <h5 className="title">{t("Find Us on")}</h5>
              <div className="social-media-icon">

                <div className="icon" >
                  <a href="https://www.facebook.com/Cannula-كانيولا-102297495710015/" target="_blank">
                    <FaFacebookF size="20" />
                  </a>
                </div>
                <div className="icon">
                  <a href="https://instagram.com/cannula.ms?utm_medium=copy_link" target="_blank">
                    <BsInstagram size="20" />
                  </a>
                </div>
                <div className="icon">
                  <a href="https://www.linkedin.com/company/cannula" target="_blank">
                    <RiLinkedinFill size="20" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-message">
          <h2 className="contact-message-title">{t("Drop your message")}</h2>
          <div className="contact-form">
            <form className="form" onSubmit={handelSubmit}>
              <div className="form-group-top">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${
                      errorMessage.name ? "error" : ""
                    }`}
                    placeholder={t("Name")}
                    name="name"
                    onChange={handelChange}
                    value={inputs.name}
                  />
                  {renderFieldError("name")}
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    className={`form-control ${
                      errorMessage.phone ? "error" : ""
                    }`}
                    placeholder={t("phone")}
                    name="phone"
                    onChange={handelChange}
                    value={inputs.phone}
                  />
                  {renderFieldError("phone")}
                </div>
              </div>
              <div className="form-group-button">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${
                      errorMessage.email ? "error" : ""
                    }`}
                    placeholder={t("Email")}
                    name="email"
                    onChange={handelChange}
                    value={inputs.email}
                  />
                  {renderFieldError("email")}
                </div>
                <div className="form-group">
                  <textarea
                    placeholder={t("Message")}
                    name="message"
                    className={`form-control ${
                      errorMessage.message ? "error" : ""
                    }`}
                    rows="5"
                    onChange={handelChange}
                    value={inputs.message}
                  />
                  {renderFieldError("message")}
                </div>
              </div>
              <div className="form-btn">
                <button type="submit" className="btn btn-submit">
                  {t("Submit")}
                </button>
                {serverState.status === "success" ? (
                  <p className="successMsg">{t("success")}</p>
                ) : serverState.status === "error" ? (
                  <p className="errorMsg">{t("error")}</p>
                ) : serverState.status === "fill" ? (
                  <p className="errorMsg">{t("fill")}</p>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
