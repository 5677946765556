import * as React from "react";
import { NavLink } from "react-router-dom";
import { IoIosMenu, IoMdClose } from "react-icons/io";

import { useTranslation } from "react-i18next";
import i18n from "../i18n";

import logo from "../assets/Group.svg";

const Header = () => {
  const { useState, useEffect } = React;
  const [navMobile, setNavMobile] = useState(false);

  const { t } = useTranslation();

  const changeLanguage = (language) => {
    return (
      setNavMobile(false),
      localStorage.setItem("language", language),
      i18n.changeLanguage(language)
    );
  };

  let Inner;
  if (typeof window !== "undefined") {
    Inner = window.innerWidth;
  }

  const [navMobileWidth, setNavMobileWidth] = useState(Inner);

  const breakpoints = 801;

  useEffect(() => {
    const handleWindowResize = () => setNavMobileWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <header className="header">
      <div
        className={
          navMobileWidth < breakpoints
            ? `header-content-mobile`
            : "header-content"
        }
      >
        <div className="header-content-banner">
          <div className="header-content-logo">
            <img src={logo} alt="logo" />
            <div className="header-content-logo-text">
              <h3>{t("CANNULA")}</h3>
            </div>
          </div>
        </div>
        {navMobileWidth < breakpoints && (
          <div className="header-content-btn">
            <button
              type="button"
              className="btn btn-nav"
              onClick={() => setNavMobile(!navMobile)}
            >
              {navMobile ? <IoMdClose size="25" /> : <IoIosMenu size="25" />}
            </button>
          </div>
        )}
        {/* NavMobile  */}
        <div
          className={
            navMobileWidth < breakpoints
              ? `header-content-nav-mobile ${navMobile ? "active" : ""}`
              : "header-content-nav"
          }
        >
          <ul>

            {i18n.language === "en" ? (
              <li>
                <a href="#about">{t("About Us")}</a>
              </li>
            ) : (
              <li>
                <a href="#about">{t("About Us")}</a>
              </li>
            )}
            <li>
              <a href="#sponsor">{t("Sponsers")}</a>
            </li>
            {i18n.language === "en" ? (
              <li>
                <a href="#contact">{t("Contact Us")}</a>
              </li>
            ) : (
              <li>
                <a href="#contact">{t("Contact Us")}</a>
              </li>
            )}
            <li>
              <a href="/اتفاقية استخدام كانيولا-converted.pdf" target="_blank">{t("doc")}</a>
            </li>
            <li>
              <select
                className="select-lan"
                onChange={(e) => changeLanguage(e.target.value)}
                value={i18n.language}
              >
                <option value="en">en</option>
                <option value="ar">ar</option>
              </select>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
