import React from "react";
import { useTranslation } from "react-i18next";

import appStoreButton from "../../assets/app.png";
import googlePlayButton from "../../assets/google play.png";

const DefComp = (props) => {
  const { t } = useTranslation();
  return (
    // <div
    //   style={{
    //     maxWidth: "100%",
    //     margin: "auto",
    //     width: "100%",
    //     height: "750px",
    //     paddingTop: "220px",
    //     paddingBottom: "220px",
    //     paddingLeft: "150px",
    //     paddingRight: "150px",
    //     // backgroundImage:`url(${this.props.background})`,
    //     background: `linear-gradient(rgba(0,0,0,0.5) , rgba(0,0,0,0.5)) , url(${props.background})`,
    //     backgroundSize: "cover",
    //     backgroundPosition: "center",
    //     backgroundRepeat: "no-repeat",
    //   }}
    //   className="comp-item"
    // >
    <div className="w-100 comp-item">
      <img
        src={props.background}
        alt="background"
        style={{
          width: "100%",
          height: "750px",
        }}
        className="comp-background"
      />

      <div className="comp-content">
        <div className="text-center definition-text-cannula p-0">
          <h1>
            {t("cannulaHead")}
            <br />
            {t("cannulaHead2")}
          </h1>
          <p>{props.content}</p>
          <div className="d-flex align-items-center justify-content-around  text-center">
            <div className="">

              <a href="https://play.google.com/store/apps/details?id=com.ahmedfouad225.cannula" target="_blank" id="android-btn">

                <img
                  width="250"
                  height="90"
                  src={googlePlayButton}
                  alt="googlePlay"
                />
              </a>
            </div>
            <div className=" mt-1 mt-xl-0">
              <a href="https://apps.apple.com/eg/app/cannula/id1602269914" target="_blank" id="ios-btn">
                <img
                  width="250"
                  height="90"
                  src={appStoreButton}
                  alt="appStore"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default DefComp;
