import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "popper.js/dist/popper.min";
import $ from "jquery";
import "jquery";

import "bootstrap/dist/js/bootstrap.min";
import "bootstrap/dist/css/bootstrap.min.css";

import "./style/App.scss";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
