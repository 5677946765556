import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Privacy from "./pages/privecy";
import Header from "./components/Header";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privecy" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
