import * as React from "react";

import { useTranslation } from "react-i18next";

// import imageApp2 from "../../assets/MOBILE2.png";
// import imageApp3 from "../../assets/MOBILE3.png";
// import imageApp4 from "../../assets/MOBILE4.png";
import DefComp from "./defComp";

import background1 from "../../assets/j.png";
import background2 from "../../assets/doctor-with-face-mask-stethoscope-consulting-disabled-senior-man-hospital-waiting-area.png";
// import background3 from "../../assets/a2.png";
const Definition = () => {
  // const { useState, useEffect } = React;
  // const { t } = useTranslation();
  //
  // const [image, setImage] = useState(imageApp2);
  // const [index, setIndex] = useState(0);
  //
  // useEffect(() => {
  //   const app = [imageApp3, imageApp4, imageApp2];
  //   const interval = setInterval(() => {
  //     if (index === 2) {
  //       setIndex(0);
  //     } else {
  //       setIndex((index) => index + 1);
  //     }
  //     setImage(app[index]);
  //   }, 3000);
  //   return () => clearInterval(interval);
  // }, [index]);

  const { t } = useTranslation();

  
  return (
    <section className="section-definition">
      <div className="section-container">
        <div
          id="carouselExampleSlidesOnly"
          className="carousel slide"
          data-bs-ride="carousel"
          // style={{width:"100%" , display:"contents"}}
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <DefComp
                background={background1}
                content={t("content1")}
                // content="خدمات طبية منزلية طارئة"
              />
            </div>
            {/* <div className="">
              <DefComp
                background={background2}
                content={t("content2")}
                // content="خدمات رعاية طبية منزلية متخصصة لكبار السن"
              />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Definition;
