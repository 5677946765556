import { useTranslation } from "react-i18next";

import image from "../../assets/cheerful-physician-serious-man-talking-about-dietary-supplements.png";

const Physician = () => {
  const { t } = useTranslation();

  return (
    <section className="section-physician" id="about">
      <div className="section-container mx-auto">
        <div className="physician-image">
          <img src={image} alt="physician" />
        </div>
        <div className="physician-text my-auto">
          <div className="physician-text-story">
            <h2>{t("Our Story")}</h2>
            <p>{t("p1")}</p>
          </div>
          <div className="physician-text-mission">
            <h2>{t("Our Mission")}</h2>
            <ul>
              <li>
                <p>{t("p2")}</p>
              </li>
              <li>
                <p>{t("p3")}</p>
              </li>
              <li>
                <p>{t("p4")}</p>
              </li>
            </ul>
          </div>
          <div className="physician-text-vision">
            <h2>{t("Our Vision")}</h2>
            <p>{t("p5")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Physician;
