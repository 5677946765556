import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import Definition from "../components/home/Definition";
import Physician from "../components/home/Physician";
import Banner from "../components/home/Banner";
import Contact from "../components/home/Contact";
import Footer from "../components/Footer";

const Home = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    if (i18n.language === "ar") {
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
    }
    if (i18n.language === "en") {
      document.body.classList.add("ltr");
    } else {
      document.body.classList.remove("ltr");
    }
  });

  return (
    <main className="main-home">
      <Definition />
      <Physician />
      <Banner />
      <Contact />
      <Footer />
    </main>
  );
};

export default Home;
